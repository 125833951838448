import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'calendar-info-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class CalendarInfoIcon {
  @Prop() width: string = '20';
  @Prop() height: string = '20';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 0C13.3807 0 14.5 1.11929 14.5 2.5V6.59971C14.1832 6.43777 13.8486 6.30564 13.5 6.20703V4H1.5V11.5C1.5 12.3284 2.17157 13 3 13H6.70703C6.80564 13.3486 6.93777 13.6832 7.09971 14H3C1.61929 14 0.5 12.8807 0.5 11.5V2.5C0.5 1.11929 1.61929 0 3 0H12ZM12 1H3C2.17157 1 1.5 1.67157 1.5 2.5V3H13.5V2.5C13.5 1.67157 12.8284 1 12 1ZM11.375 9.5C11.375 9.15482 11.6548 8.875 12 8.875C12.3452 8.875 12.625 9.15482 12.625 9.5C12.625 9.84518 12.3452 10.125 12 10.125C11.6548 10.125 11.375 9.84518 11.375 9.5ZM12.5 13.5C12.5 13.7761 12.2761 14 12 14C11.7239 14 11.5 13.7761 11.5 13.5V11.5C11.5 11.2239 11.7239 11 12 11C12.2761 11 12.5 11.2239 12.5 11.5V13.5ZM7.5 11.5C7.5 9.01472 9.51472 7 12 7C14.4853 7 16.5 9.01472 16.5 11.5C16.5 13.9853 14.4853 16 12 16C9.51472 16 7.5 13.9853 7.5 11.5ZM8.5 11.5C8.5 13.433 10.067 15 12 15C13.933 15 15.5 13.433 15.5 11.5C15.5 9.567 13.933 8 12 8C10.067 8 8.5 9.567 8.5 11.5Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
