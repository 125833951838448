import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'google-meet-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class GoogleMeetIcon {
  @Prop() width: string = '20';
  @Prop() height: string = '20';

  render() {
    // Spam icon svg
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_22_6251)">
          <path d="M11.3145 10.1036L13.2642 12.3321L15.8859 14.0076L16.343 10.1173L15.8859 6.31384L13.2139 7.78584L11.3145 10.1036Z" fill="#00832D" />
          <path d="M0 13.6464V16.9607C0 17.7184 0.613714 18.3321 1.37143 18.3321H4.68571L5.37143 15.827L4.68571 13.6464L2.41143 12.9607L0 13.6464Z" fill="#0066DA" />
          <path d="M4.68571 1.875L0 6.56071L2.41143 7.24643L4.68571 6.56071L5.36 4.40986L4.68571 1.875Z" fill="#E94235" />
          <path d="M4.68571 6.56067H0V13.6464H4.68571V6.56067Z" fill="#2684FC" />
          <path
            d="M18.8798 3.85895L15.8855 6.31381V14.0075L18.8935 16.4738C19.3438 16.8258 20.0021 16.5047 20.0021 15.9321V4.38923C20.0021 3.80981 19.329 3.49209 18.8798 3.85895ZM11.3141 10.1035V13.6464H4.68555V18.3321H14.5141C15.2718 18.3321 15.8855 17.7184 15.8855 16.9607V14.0075L11.3141 10.1035Z"
            fill="#00AC47"
          />
          <path d="M14.5141 1.875H4.68555V6.56071H11.3141V10.1036L15.8855 6.31614V3.24643C15.8855 2.48871 15.2718 1.875 14.5141 1.875Z" fill="#FFBA00" />
        </g>
        <defs>
          <clipPath id="clip0_22_6251">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
