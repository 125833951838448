import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug, sanitize } from '@/utils/utils';
import { AttachInternals, Component, Host, State, h, Element, Prop, Watch, Event, EventEmitter, Listen } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Conference, Configuration } from '@nylas/core';
import { CONFERENCE_PROVIDER_MAP, PROVIDER_CONFERENCE_MAP } from '@/common/constants';
import { User } from '@/common/nylas-api-request';

const labels = {
  none: 'None',
  custom: 'Custom location',
  google: 'Google Meet',
  microsoft: 'Microsoft Teams',
  zoom: 'Zoom Meeting',
};

/**
 * The `nylas-event-location` component is a form input for the location of an event.
 *
 * @part nel - The event location container
 * @part nel__location - The event location input
 * @part nel__dropdown - The dropdown container
 * @part nel__dropdown-button - The dropdown button
 * @part nel__dropdown-content - The dropdown content
 */
@Component({
  tag: 'nylas-event-location',
  styleUrl: 'nylas-event-location.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasEventLocation {
  /*
   * The host element <nylas-event-location>
   */
  @Element() host!: HTMLElement;
  /**
   * @standalone
   * The name of the event location input.
   */
  @Prop() name: string = 'location';

  /**
   * @standalone
   * The current user
   */
  @Prop() currentUser?: User;
  /**
   * @standalone
   * The users provider
   */
  @Prop() userProvider?: string;
  /**
   * @standalone
   * The event conferencing stored in the configuration
   */
  @Prop() eventConferencing?: Conference;
  /**
   * @standalone
   * The conference providers where the key is the provider name ('zoom') and the value is the grant id.
   * We currently support same provider ('google', 'microsoft') for all participants and 'zoom' for cross-provider conferencing.
   */
  @Prop() conferenceProviders?: Record<string, string>;

  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;

  /**
   * @standalone
   * The event location stored in the configuration
   */
  @Prop() eventLocation?: string;
  /**
   * The selected event location state. This defaults to the event location from the configuration or an empty string.
   */
  @State() location: string = this.eventLocation ?? '';
  /**
   * The grant id for the conferencing of non-default conferencing options.
   */
  @State() grantId: string = this.eventConferencing ? this.setDefaultGrantID(this.eventConferencing) : '';
  /**
   * The selected location option. This defaults to 'none'.
   */
  @State() selectedLocationOption!: string;
  /**
   * The current user provider state.
   */
  @State() userProviderState: string = this.userProvider ?? this.currentUser?.provider ?? '';

  /**
   * The state to store location options.
   */
  @State() locationOptions = [
    { value: 'none', label: labels['none'], labelHTML: this.getLabelHTML('none') },
    { value: 'custom', label: labels['custom'], labelHTML: this.getLabelHTML('custom') },
  ];

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-event-location', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-custom-booking-flow', 'selectedConfigurationChangedHandler', newValue);
    const configLocation = newValue?.event_booking?.location;
    const configConferencing = newValue?.event_booking?.conferencing;
    if (configConferencing?.provider) {
      this.selectedLocationOption = this.setDefaultLocationOption(configConferencing, configLocation ?? '');
      this.grantId = this.setDefaultGrantID(configConferencing);
      this.location = configLocation ? configLocation : '';
    } else if (configLocation) {
      this.selectedLocationOption = configLocation ? 'custom' : 'none';
      this.location = configLocation;
    } else {
      this.selectedLocationOption = 'none';
      this.location = '';
    }
  }

  /**
   * This event is fired when the value of the event location changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  connectedCallback() {
    debug('nylas-event-location', 'connectedCallback');
  }

  componentWillLoad() {
    debug('nylas-event-location', 'componentWillLoad');
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-event-location', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      this.selectedLocationOption = this.setDefaultLocationOption(this.eventConferencing || null, this.eventLocation ?? '');
    }

    this.userProviderState = this.userProvider ?? this.currentUser?.provider ?? '';
    switch (this.userProviderState) {
      case 'google':
        this.locationOptions.push({ value: 'google', label: labels['google'], labelHTML: this.getLabelHTML('google') });
        break;
      case 'microsoft':
        this.locationOptions.push({ value: 'microsoft', label: labels['microsoft'], labelHTML: this.getLabelHTML('microsoft') });
        break;
    }

    if (this.conferenceProviders?.['zoom']) {
      this.locationOptions.push({ value: 'zoom', label: labels['zoom'], labelHTML: this.getLabelHTML('zoom') });
    }
  }

  disconnectedCallback() {
    debug('nylas-event-location', 'disconnectedCallback');
  }

  @Listen('nylasFormDropdownChanged')
  nylasFormDropdownChangedHandler(event: CustomEvent<{ value: string }>) {
    this.selectedLocationOption = event.detail.value;
    let payload;
    switch (this.selectedLocationOption) {
      case 'none':
        this.location = '';
        this.setFormValue('', this.name);
        this.valueChanged.emit({ value: '', name: this.name });
        this.valueChanged.emit({ value: JSON.stringify(null), name: 'conference' });
        break;
      case 'custom':
        this.valueChanged.emit({ value: JSON.stringify(null), name: 'conference' });
        break;
      case 'google':
        this.location = '';
        this.setFormValue('', this.name);
        const googleConfGrantId = this.conferenceProviders?.['google'];
        payload = {
          provider: PROVIDER_CONFERENCE_MAP.google,
          autocreate: googleConfGrantId
            ? {
                conf_grant_id: googleConfGrantId,
              }
            : {},
        };
        this.valueChanged.emit({ value: JSON.stringify(payload), name: 'conference' });
        this.valueChanged.emit({ value: '', name: this.name });
        break;
      case 'microsoft':
        this.location = '';
        this.setFormValue('', this.name);
        const microsoftConfGrantId = this.conferenceProviders?.['microsoft'];
        payload = {
          provider: PROVIDER_CONFERENCE_MAP.microsoft,
          autocreate: microsoftConfGrantId
            ? {
                conf_grant_id: microsoftConfGrantId,
              }
            : {},
        };
        this.valueChanged.emit({ value: JSON.stringify(payload), name: 'conference' });
        this.valueChanged.emit({ value: '', name: this.name });
        break;
      case 'zoom':
        this.location = '';
        this.setFormValue('', this.name);
        const zoomConfGrantId = this.conferenceProviders?.['zoom'];
        payload = {
          provider: PROVIDER_CONFERENCE_MAP.zoom,
          autocreate: zoomConfGrantId
            ? {
                conf_grant_id: zoomConfGrantId,
              }
            : {},
        };
        this.valueChanged.emit({ value: JSON.stringify(payload), name: 'conference' });
        this.valueChanged.emit({ value: '', name: this.name });
        break;
    }
  }

  setDefaultLocationOption(eventConferencing: Conference | null, eventLocation: string) {
    if (eventConferencing != null && eventConferencing?.provider != null && eventConferencing?.autocreate != null) {
      return CONFERENCE_PROVIDER_MAP[eventConferencing?.provider];
    }
    return eventLocation ? 'custom' : 'none';
  }
  setDefaultGrantID(eventConferencing: Conference) {
    if (eventConferencing != null && eventConferencing?.autocreate != null) {
      return eventConferencing?.autocreate?.conf_grant_id;
    }
    return '';
  }

  setFormValue(value: string, name: string) {
    if (typeof this.internals.setFormValue === 'function') {
      this.internals.setFormValue(value, name);
    }
  }

  handleChange(event) {
    const value = sanitize(event.target.value);
    this.location = value;
    this.setFormValue(value, this.name);
    this.valueChanged.emit({ value: value, name: this.name });
  }

  getIcon(location: string) {
    switch (location) {
      case 'none':
        return <location-off-icon />;
      case 'custom':
        return <location-icon />;
      case 'google':
        return <google-meet-icon />;
      case 'microsoft':
        return <microsoft-teams-icon />;
      case 'zoom':
        return <zoom-icon />;
    }
  }

  getLabelHTML(location: string) {
    return (
      <span
        class="location-label"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          width: '-webkit-fill-available',
        }}
      >
        {this.getIcon(location)}
        <span
          class="location-text"
          style={{
            fontSize: '14px',
            fontWeight: '400',
          }}
        >
          {labels[location]}
        </span>
      </span>
    );
  }

  @RegisterComponent<NylasEventLocation, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-event-location',
    stateToProps: new Map([
      ['schedulerConfig.selectedConfiguration', 'selectedConfiguration'],
      ['schedulerConfig.conferenceProviders', 'conferenceProviders'],
      ['schedulerConfig.currentUser', 'currentUser'],
    ]),
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    const defaultOption = typeof this.selectedLocationOption !== 'undefined' ? this.locationOptions.find(i => i.value == this.selectedLocationOption) : null;
    return (
      <Host>
        <div class="nylas-event-location" part="nel">
          <label htmlFor="location">
            Event meeting or location
            <span class="label-icon">
              <tooltip-component>
                <info-icon slot="tooltip-icon" />
                <span slot="tooltip-content">Select a conferencing option or add a custom location.</span>
              </tooltip-component>
            </span>
          </label>
          <div class="location-input">
            {defaultOption && defaultOption?.label && (
              <select-dropdown
                withSearch={false}
                name="location"
                options={this.locationOptions}
                defaultSelectedOption={defaultOption}
                exportparts="sd_dropdown: nel__dropdown, sd_dropdown-button: nel__dropdown-button, sd_dropdown-content: nel__dropdown-content"
              >
                <span slot="select-icon">{this.getIcon(this.selectedLocationOption)}</span>
              </select-dropdown>
            )}
            {this.selectedLocationOption === 'custom' && (
              <input type="text" part="nel__location" id="location" name="location" maxlength="1024" value={this.location} onInput={e => this.handleChange(e)} />
            )}
          </div>
        </div>
      </Host>
    );
  }
}
