import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'paintbrush-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class PaintbrushIcon {
  @Prop() width: string = '16';
  @Prop() height: string = '13';

  render() {
    return (
      <svg width="16" height="16" viewBox="-3 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 -0.000976562C0.223858 -0.000976562 0 0.222881 0 0.499023L1.90735e-05 9.00337C1.90735e-05 10.1079 0.89545 11.0034 2.00002 11.0034H3.00411V14.0007C3.00411 15.1053 3.89954 16.0007 5.00411 16.0007C6.10868 16.0007 7.00411 15.1053 7.00411 14.0007V11.0034H8.00002C9.10459 11.0034 10 10.1079 10 9.00337V7.50339L10 7.49897V0.499023C10 0.222881 9.77614 -0.000976562 9.5 -0.000976562H0.5ZM1.00002 8.00342H9.00002V9.00337C9.00002 9.55566 8.5523 10.0034 8.00002 10.0034H6.50411C6.22797 10.0034 6.00411 10.2272 6.00411 10.5034V14.0007C6.00411 14.553 5.5564 15.0007 5.00411 15.0007C4.45183 15.0007 4.00411 14.553 4.00411 14.0007V10.5034C4.00411 10.2272 3.78026 10.0034 3.50411 10.0034H2.00002C1.44773 10.0034 1.00002 9.55566 1.00002 9.00337V8.00342ZM9 7.00339H1V0.999023H5V2.49902C5 2.77517 5.22386 2.99902 5.5 2.99902C5.77614 2.99902 6 2.77517 6 2.49902V0.999023H7V3.50122C7 3.77736 7.22386 4.00122 7.5 4.00122C7.77614 4.00122 8 3.77736 8 3.50122V0.999023H9V7.00339Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
