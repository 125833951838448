import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'people-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class PeopleIcon {
  @Prop() width: string = '20';
  @Prop() height: string = '20';

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.width} height={this.height} fill="none" viewBox="0 0 20 20">
        <path
          fill="currentColor"
          d="M11 10a2 2 0 0 1 2 2v1.5c0 2.054-2.088 3.5-5.5 3.5S2 15.554 2 13.5V12a2 2 0 0 1 2-2h7Zm0 1H4a1 1 0 0 0-1 1v1.5C3 14.907 4.579 16 7.5 16c2.921 0 4.5-1.093 4.5-2.5V12a1 1 0 0 0-1-1Zm5-1a2 2 0 0 1 2 2v.5c0 2.089-1.568 3.5-4.5 3.5-.141 0-.28-.003-.414-.01.208-.242.382-.502.522-.781l.097-.213h.039C15.976 14.931 17 13.96 17 12.5V12a1 1 0 0 0-1-1h-2.171a3 3 0 0 0-.594-1H16ZM7.5 2a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7Zm7 2a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm-7-1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm7 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
        />
      </svg>
    );
  }
}
