import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'eye-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class EyeIcon {
  @Prop() width: string = '17';
  @Prop() height: string = '10';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.75909 6.60214C2.44254 3.32689 5.29437 1 8.50002 1C11.7057 1 14.5574 3.32688 15.2409 6.60215C15.2974 6.87246 15.5622 7.04587 15.8325 6.98946C16.1029 6.93304 16.2763 6.66817 16.2199 6.39785C15.4425 2.67312 12.1934 0 8.50002 0C4.8066 0 1.55742 2.67311 0.780175 6.39786C0.723767 6.66818 0.897177 6.93305 1.1675 6.98946C1.43782 7.04587 1.70268 6.87246 1.75909 6.60214ZM8.5 3C6.567 3 5 4.567 5 6.5C5 8.433 6.567 10 8.5 10C10.433 10 12 8.433 12 6.5C12 4.567 10.433 3 8.5 3ZM6 6.5C6 5.11929 7.11929 4 8.5 4C9.88071 4 11 5.11929 11 6.5C11 7.88071 9.88071 9 8.5 9C7.11929 9 6 7.88071 6 6.5Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
