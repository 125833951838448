@import '../../../common/styles/variables.scss';

:host {
  @include default-css-variables;
}

.nylas-limit-future-bookings {
  display: flex;
  justify-content: space-between;
  font-family: var(--nylas-font-family);
  @media #{$mobile} {
    flex-direction: column;
    gap: 0.5rem;
  }
  label {
    display: flex;
    align-items: center;
    color: var(--nylas-base-800);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    span.label-icon {
      margin-left: 4px;
      tooltip-component {
        display: flex;
      }
    }
  }
}

select-dropdown::part(sd_dropdown-button) {
  border: none;
}
