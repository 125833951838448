@import '../../../common/styles/variables.scss';

:host {
  display: block;
  margin: 1rem;
  @include default-css-variables;
}

.nylas-event-info__title {
  color: var(--nylas-base-900);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  margin: 0;
  text-align: left;
}
.nylas-event-info__subtitle {
  color: var(--nylas-base-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 0.25rem 0px 0px;
  text-align: left;
}
.nylas-event-info__content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.nylas-event-info__block {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}
