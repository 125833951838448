import { Component, h, Prop, State, Event, EventEmitter } from '@stencil/core';

/**
 * The `toggle-switch` component is a UI component that allows users to toggle a switch.
 * This component is used in the scheduling form to input toggle switch type inputs.
 * Use it as a replacement for checkboxes when you want to present a switch in the UI.
 *
 * @part ts_label - The label of the toggle switch
 * @part ts_input - The input of the toggle switch
 * @part ts_slider - The slider of the toggle switch
 */
@Component({
  tag: 'toggle-switch',
  styleUrl: 'toggle-switch.scss',
  shadow: true,
})
export class ToggleSwitchComponent {
  /**
   * The name of the toggle switch. This is used to identify the toggle switch when submitting a form.
   */
  @Prop() name: string = 'toggle-switch';
  /**
   * The default value of the toggle switch. This is the value that is displayed when the toggle switch is rendered.
   */
  @Prop() checked: boolean = false;
  /**
   * The label of the toggle switch. This is displayed next to the toggle switch.
   */
  @Prop() label: string = '';

  /**
   * The checked state of the toggle switch.
   */
  @State() isChecked: boolean = this.checked;

  /**
   * This event is fired when the toggle switch is toggled.
   */
  @Event() nylasFormSwitchToggled!: EventEmitter<{
    checked: boolean;
    name: string;
    label: string;
  }>;

  // Lifecycle events
  componentWillLoad() {
    this.isChecked = this.checked;
  }

  toggleCheck() {
    this.isChecked = !this.isChecked;
    this.nylasFormSwitchToggled.emit({
      checked: this.isChecked,
      name: this.name,
      label: this.label,
    });
  }

  render() {
    return (
      <label class="switch" part="ts_label">
        <input type="checkbox" part="ts_input" name={this.name} checked={this.isChecked} onChange={() => this.toggleCheck()} />
        <div class="slider round" part="ts_slider"></div>
      </label>
    );
  }
}
