@import '../../../common/styles/variables.scss';

:host {
  @include default-css-variables;
}

.nylas-event-description {
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    display: flex;
    align-items: center;
    color: var(--nylas-base-800);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    span.label-icon {
      margin-left: 4px;
      tooltip-component {
        display: flex;
      }
    }
  }
  textarea {
    padding: 12px 16px;
    border-width: 1;
    resize: vertical;
    border-radius: 8px;
    font-family: var(--nylas-font-family);
    font-size: 16px;
    line-height: 24px;
    border: 1px solid var(--nylas-base-200);
    &::-webkit-resizer {
      display: none;
    }
  }
}
