@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
}

.nylas-event-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  text-align: left;

  div.title {
    padding: 12px 16px;
    border: 1px solid var(--nylas-base-200);
    border-radius: var(--nylas-border-radius-2x);
    overflow: scroll;
    white-space: nowrap;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &.error {
      border-color: var(--nylas-error);
      border-width: 2px;
      outline: none;
    }
  }

  input {
    padding: 12px 16px;
    border-width: 1;
    border-radius: 8px;
    border: 1px solid var(--nylas-base-200);
  }

  span.help-text {
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--nylas-base-800);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  span.error-message {
    color: var(--nylas-error);
  }

  label {
    display: flex;
    align-items: center;
    color: var(--nylas-base-800);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    /* 21px */
    span.required {
      color: var(--nylas-error, #cc4841);
    }

    span.label-icon {
      margin-left: 4px;

      tooltip-component {
        display: flex;
      }
    }
  }
}

.highlighted-tag {
  background-color: var(--nylas-base-200);
  border-radius: var(--nylas-border-radius);
  padding: 5px;
  margin-left: 4px;
}

.token-options {
  display: block;
  background-color: var(--nylas-base-0);
  width: 100%;
  max-height: 336px;
  overflow: auto;
  z-index: 1;
  border-radius: 4px;
  position: absolute;
  top: calc(48px + 24px + 8px); // 48px is the height of the input, 24px is the height of the label, 8px is the gap between the label and the input

  @media #{$mobile} {
    right: 0;
    width: 325px;
    max-width: unset;
  }

  box-shadow: 0px 4px 6px -2px #0000000d;
  box-shadow: 0px 10px 15px -3px #0000001a;

  ul {
    padding: 0;
    list-style-type: none;
    color: var(--nylas-base-900);
    max-height: 336px;
    margin: 0;

    li {
      padding: 16px, 12px, 16px, 12px;
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      font-family: inherit;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      cursor: pointer;

      .token-label {
        display: flex;
        flex-direction: column;
        font-weight: 400;

        .token {
          color: var(--nylas-abse-900);
          font-size: 16px;
          line-height: 24px;
        }

        .description {
          color: var(--nylas-base-600);
          font-size: 14px;
          line-height: 21px;
        }
      }

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: var(--nylas-base-100);

        .token-label {
          .token {
            color: var(--nylas-primary);
          }
        }
      }
    }
  }

  .selected {
    background-color: var(--nylas-base-100);
  }
}
