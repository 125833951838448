import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'calendar-agenda-fill-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class CalendarAgendaFillIcon {
  @Prop() width: string = '20';
  @Prop() height: string = '20';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 11.5C14 12.8807 12.8807 14 11.5 14H2.5C1.11929 14 0 12.8807 0 11.5V2.5C0 1.11929 1.11929 0 2.5 0H11.5C12.8807 0 14 1.11929 14 2.5V11.5ZM11 3.5C11 3.25454 10.8231 3.05039 10.5899 3.00806L10.5 3H3.5L3.41012 3.00806C3.17688 3.05039 3 3.25454 3 3.5C3 3.74546 3.17688 3.94961 3.41012 3.99194L3.5 4H10.5L10.5899 3.99194C10.8231 3.94961 11 3.74546 11 3.5ZM11 7C11 6.75454 10.8231 6.55039 10.5899 6.50806L10.5 6.5H3.5L3.41012 6.50806C3.17688 6.55039 3 6.75454 3 7C3 7.24546 3.17688 7.44961 3.41012 7.49194L3.5 7.5H10.5L10.5899 7.49194C10.8231 7.44961 11 7.24546 11 7ZM11 10.5C11 10.2545 10.8231 10.0504 10.5899 10.0081L10.5 10H3.5L3.41012 10.0081C3.17688 10.0504 3 10.2545 3 10.5C3 10.7455 3.17688 10.9496 3.41012 10.9919L3.5 11H10.5L10.5899 10.9919C10.8231 10.9496 11 10.7455 11 10.5Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
