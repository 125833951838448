import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'trash-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class TrashIcon {
  @Prop() width: string = '20';
  @Prop() height: string = '21';

  render() {
    // Trash icon svg
    return (
      <svg width={this.width} height={this.height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 0.25C11.733 0.25 13.1492 1.60645 13.2449 3.31558L13.25 3.5H18.5C18.9142 3.5 19.25 3.83579 19.25 4.25C19.25 4.6297 18.9678 4.94349 18.6018 4.99315L18.5 5H17.704L16.4239 18.0192C16.2912 19.3683 15.1984 20.41 13.8626 20.4945L13.6871 20.5H6.31293C4.95734 20.5 3.81365 19.5145 3.59883 18.1934L3.57614 18.0192L2.295 5H1.5C1.1203 5 0.806509 4.71785 0.756847 4.35177L0.75 4.25C0.75 3.8703 1.03215 3.55651 1.39823 3.50685L1.5 3.5H6.75C6.75 1.70507 8.20507 0.25 10 0.25ZM16.197 5H3.802L5.06893 17.8724C5.12768 18.4696 5.60033 18.9343 6.18585 18.9936L6.31293 19H13.6871C14.2872 19 14.7959 18.5751 14.9123 17.9982L14.9311 17.8724L16.197 5ZM11.75 7.75C12.1297 7.75 12.4435 8.03215 12.4932 8.39823L12.5 8.5V15.5C12.5 15.9142 12.1642 16.25 11.75 16.25C11.3703 16.25 11.0565 15.9678 11.0068 15.6018L11 15.5V8.5C11 8.08579 11.3358 7.75 11.75 7.75ZM8.25 7.75C8.6297 7.75 8.94349 8.03215 8.99315 8.39823L9 8.5V15.5C9 15.9142 8.66421 16.25 8.25 16.25C7.8703 16.25 7.55651 15.9678 7.50685 15.6018L7.5 15.5V8.5C7.5 8.08579 7.83579 7.75 8.25 7.75ZM10 1.75C9.08183 1.75 8.32881 2.45711 8.2558 3.35647L8.25 3.5H11.75C11.75 2.5335 10.9665 1.75 10 1.75Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
