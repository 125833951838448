@import '../../../common/styles/variables.scss';

:host {
  @include default-css-variables;
}

.nylas-event-location {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: var(--nylas-font-family);

  label {
    display: flex;
    align-items: center;
    color: var(--nylas-base-800);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    span.label-icon {
      margin-left: 4px;
      tooltip-component {
        display: flex;
      }
    }
  }

  span[slot='select-icon'] {
    border-right: 1px solid var(--nylas-base-200);
    padding: 16px 0.75rem 16px 0.5rem;
    border-radius: var(--nylas-border-radius-2x);
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    height: 20px;
  }
  div.location-input {
    display: grid;
    grid-template-columns: auto 1fr;
    width: 100%;
    gap: 0.5rem;
    @media #{$mobile} {
      grid-template-columns: 1fr;
    }
    input {
      border: 1px solid var(--nylas-base-200);
      border-radius: var(--nylas-border-radius-2x);
      padding: 12px 16px;
      font-family: var(--nylas-font-family);
      font-size: 16px;
      line-height: 24px;
    }
  }
  select-dropdown {
    width: 208px;
    @media #{$mobile} {
      width: 100%;
    }
  }
  select-dropdown::part(sd_dropdown) {
    width: 100%;
  }
  select-dropdown::part(sd_dropdown-content) {
    left: calc(35px + 0.5rem);
  }
  select-dropdown::part(sd_dropdown-button) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid var(--nylas-base-200);
    padding: 0 0.5rem;
  }
  select-dropdown::part(sd_dropdown-button-selected-label) {
    display: inline-flex;
    align-self: center;
    padding: 14px 0;
    font-family: var(--nylas-font-family);
    font-size: 16px;
    line-height: 24px;
  }
}
