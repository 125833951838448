import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Host, State, h, Element, Prop, Watch, Event, EventEmitter, Listen } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration } from '@nylas/core';

/**
 * The `nylas-limit-future-bookings` component is a form input for the number of days in the future a guest is allowed to book an event.
 *
 * @part nlfb - The limit future bookings container
 * @part nlfb__number-dropdown - The number dropdown container
 * @part nlfb__number-dropdown-button - The number dropdown button
 * @part nlfb__number-dropdown-content - The number dropdown content
 * @part nlfb__period-dropdown - The period dropdown container
 * @part nlfb__period-dropdown-button - The period dropdown button
 * @part nlfb__period-dropdown-content - The period dropdown content
 */
@Component({
  tag: 'nylas-limit-future-bookings',
  styleUrl: 'nylas-limit-future-bookings.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasLimitFutureBookings {
  /**
   * The host element <nylas-limit-future-bookings>
   */
  @Element() host!: HTMLElement;
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * @standalone
   * The number of days into the future that invitees will see availability, as set in the configuration.
   */
  @Prop() availableDaysInFuture?: number;
  /**
   * @standalone
   * The name of the limit future bookings input.
   */
  @Prop() name: string = 'limit-future-bookings';

  /**
   * The selected number for the available days in the future.
   */
  @State() selectedNumber;
  /**
   * The selected period for the available days in the future.
   */
  @State() selectedPeriod;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-limit-future-bookings', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newVal: Configuration) {
    const daysInFuture = newVal?.scheduler?.available_days_in_future || this.availableDaysInFuture || 1;
    if (daysInFuture) {
      this.updateNumberAndPeriodFromDays(daysInFuture);
    }
    if (typeof this.internals.setFormValue !== 'function') {
      return;
    }
    this.internals.setFormValue(daysInFuture?.toString(), this.name);
  }

  /**
   * Event emitted when the future booking limit changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  connectedCallback() {
    debug('nylas-limit-future-bookings', 'connectedCallback');
  }

  componentWillLoad() {
    debug('nylas-limit-future-bookings', 'componentWillLoad');
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-limit-future-bookings', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      this.updateNumberAndPeriodFromDays(this.availableDaysInFuture || 1);
    }
  }

  disconnectedCallback() {
    debug('nylas-limit-future-bookings', 'disconnectedCallback');
  }

  @Listen('timePeriodChanged')
  timePeriodChangedHandler(
    event: CustomEvent<{
      number: number;
      period: string;
    }>,
  ) {
    const { number, period } = event.detail;
    let availableDaysInFuture;
    if (period === 'day') {
      availableDaysInFuture = number;
    } else if (period === 'week') {
      availableDaysInFuture = number * 7;
    } else {
      availableDaysInFuture = number * 30;
    }
    this.valueChanged.emit({ value: availableDaysInFuture, name: 'availableDaysInFuture' });
    if (typeof this.internals.setFormValue !== 'function') {
      return;
    }
    this.internals.setFormValue(availableDaysInFuture.toString(), this.name);
  }

  updateNumberAndPeriodFromDays(days: number): void {
    let number, period;
    if (days >= 30 && days % 30 === 0) {
      number = days / 30;
      period = 'month';
    } else if (days >= 7 && days % 7 === 0) {
      number = days / 7;
      period = 'week';
    } else {
      number = days;
      period = 'day';
    }
    this.selectedNumber = number;
    this.selectedPeriod = period;
  }

  @RegisterComponent<NylasLimitFutureBookings, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-limit-future-bookings',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    const showTImePeriodSelector = typeof this.selectedNumber == 'number' && typeof this.selectedPeriod == 'string';
    return (
      <Host>
        <div class="nylas-limit-future-bookings" part="nlfb">
          <label>
            Limit future bookings
            <span class="label-icon">
              <tooltip-component>
                <info-icon slot="tooltip-icon" />
                <span slot="tooltip-content">The limit on how far in the future bookings can be made.</span>
              </tooltip-component>
            </span>
          </label>
          {showTImePeriodSelector && (
            <time-period-selector
              id="limit-future-bookings"
              timePeriods={['day', 'week', 'month']}
              exportparts="tps__number-dropdown: nlfb__number-dropdown, tps__number-dropdown-button: nlfb__number-dropdown-button, tps__number-dropdown-content: nlfb__number-dropdown-content, tps__period-dropdown: nlfb__period-dropdown, tps__period-dropdown-button: nlfb__period-dropdown-button, tps__period-dropdown-content: nlfb__period-dropdown-content"
              defaultSelectedNumber={this.selectedNumber}
              defaultSelectedPeriod={this.selectedPeriod}
            />
          )}
        </div>
      </Host>
    );
  }
}
