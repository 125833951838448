import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'flow-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class FlowIcon {
  @Prop() width: string = '16';
  @Prop() height: string = '12';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 1.66211C11.8954 1.66211 11 2.55754 11 3.66211C11 4.76668 11.8954 5.66211 13 5.66211C14.1046 5.66211 15 4.76668 15 3.66211C15 2.55754 14.1046 1.66211 13 1.66211ZM10.0407 3.16678C10.2768 1.74563 11.5119 0.662109 13 0.662109C14.6569 0.662109 16 2.00526 16 3.66211C16 5.31896 14.6569 6.66211 13 6.66211C11.5162 6.66211 10.2839 5.58482 10.0428 4.16971C9.37629 4.23688 8.82682 4.7426 8.71466 5.41551L8.27173 8.07311C8.07929 9.22776 7.11428 10.087 5.9593 10.1574C5.72319 11.5786 4.48813 12.6621 3 12.6621C1.34315 12.6621 0 11.319 0 9.66211C0 8.00526 1.34315 6.66211 3 6.66211C4.48384 6.66211 5.71607 7.73939 5.95724 9.15451C6.62371 9.08734 7.17318 8.58162 7.28534 7.90871L7.72827 5.25111C7.92071 4.09646 8.88571 3.23718 10.0407 3.16678ZM3 7.66211C1.89543 7.66211 1 8.55754 1 9.66211C1 10.7667 1.89543 11.6621 3 11.6621C4.10457 11.6621 5 10.7667 5 9.66211C5 8.55754 4.10457 7.66211 3 7.66211Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
