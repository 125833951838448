import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'feedback-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class FeedbackIcon {
  @Prop() width: string = '11';
  @Prop() height: string = '14';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 8.875C0.5 8.875 1.125 8.25 3 8.25C4.875 8.25 6.125 9.5 8 9.5C9.875 9.5 10.5 8.875 10.5 8.875V1.375C10.5 1.375 9.875 2 8 2C6.125 2 4.875 0.75 3 0.75C1.125 0.75 0.5 1.375 0.5 1.375V8.875ZM0.5 8.875V13.25"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
}
