import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Host, Listen, State, h, Element, Prop, Watch, Event, EventEmitter } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration } from '@nylas/core';

/**
 * The `nylas-event-duration` component is a form input for the duration of an event.
 *
 * @part ned - The event duration container
 * @part ned__dropdown - The dropdown container for the duration increment
 * @part ned__dropdown-button - The dropdown button for the duration increment
 * @part ned__dropdown-content - The dropdown content for the duration increment
 * @part ned__input_dropdown - The input dropdown container for the duration minutes
 * @part ned__input_dropdown-input - The input for the duration minutes
 * @part ned__input_dropdown-content - The dropdown content for the input duration minutes
 */
@Component({
  tag: 'nylas-event-duration',
  styleUrl: 'nylas-event-duration.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasEventDuration {
  /**
   * The host element <nylas-event-duration>
   */
  @Element() host!: HTMLElement;
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;

  /**
   * @standalone
   * The name of the event duration. Default is 'duration'.
   */
  @Prop() name: string = 'duration';
  /**
   * @standalone
   * The event duration in minutes as set in the configuration.
   */
  @Prop() eventDurationMinutes?: number;

  /**
   * The selected event duration state. This defaults to the event duration from the configuration or 30 minutes.
   */
  @State() duration: number = this.eventDurationMinutes ? (this.eventDurationMinutes >= 60 ? this.eventDurationMinutes / 60 : this.eventDurationMinutes) : 30;
  /**
   * The duration in minutes. This defaults to the event duration from the configuration or 30 minutes.
   */
  @State() durationMinutes!: number;
  /**
   * The duration increment. This defaults to minute(s).
   */
  @State() durationIncrement!: number;
  /**
   * The durationMinutesOptions for the dropdown.
   */
  @State() durationMinutesOptions = [
    { value: 15, label: '15' },
    { value: 30, label: '30' },
    { value: 45, label: '45' },
    { value: 60, label: '60' },
  ];

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-event-duration', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newVal: Configuration) {
    const configDuration = newVal?.availability?.duration_minutes;
    if (configDuration) {
      this.duration = configDuration >= 60 ? configDuration / 60 : configDuration;
      this.durationMinutes = configDuration ? configDuration : 30;
      this.durationIncrement = configDuration >= 60 ? 60 : 1;
    }
  }

  @Watch('durationMinutes')
  durationMinutesChangedHandler(newValue: number, oldValue: number) {
    debug('nylas-event-duration', 'durationMinutesChangedHandler', newValue);
    if (newValue === oldValue) {
      return;
    }
    this.valueChanged.emit({ value: newValue.toString(), name: this.name });
  }

  /**
   * Event emitted when the event duration changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  connectedCallback() {
    debug('nylas-event-duration', 'connectedCallback');
  }

  componentWillLoad() {
    debug('nylas-event-duration', 'componentWillLoad');
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-event-duration', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    }
    this.duration = this.duration ? this.duration : this.eventDurationMinutes ? (this.eventDurationMinutes >= 60 ? this.eventDurationMinutes / 60 : this.eventDurationMinutes) : 30;
    this.durationMinutes = this.durationMinutes ? this.durationMinutes : this.duration ? this.duration : 30;
    this.durationIncrement = this.durationIncrement ? this.durationIncrement : this.duration >= 60 ? 60 : 1;
    this.setDurationMintueOptions();
    this.isInternalsAvailable && this.internals.setFormValue(this.durationMinutes.toString(), 'duration');
  }

  disconnectedCallback() {
    debug('nylas-event-duration', 'disconnectedCallback');
  }

  get isInternalsAvailable() {
    return typeof this.internals !== 'undefined' && typeof this.internals.setFormValue === 'function';
  }

  setDurationMintueOptions() {
    if (this.durationIncrement === 1) {
      this.durationMinutesOptions = [
        { value: 15, label: '15' },
        { value: 30, label: '30' },
        { value: 45, label: '45' },
        { value: 60, label: '60' },
      ];
    } else {
      this.durationMinutesOptions = Array.from({ length: 23 }, (_, i) => ({ value: i + 1, label: (i + 1).toString() }));
    }
    return this.durationMinutesOptions;
  }

  handleChange(event) {
    this.duration = event.target.value;
    this.durationMinutes = this.duration * this.durationIncrement;
    this.isInternalsAvailable && this.internals.setFormValue(this.durationMinutes.toString(), this.name);
  }

  @Listen('nylasFormDropdownChanged')
  nylasFormDropdownChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('nylas-calendar-picker', 'nylasFormDropdownChangedHandler', event.detail);
    const { value } = event.detail;
    this.durationIncrement = parseInt(value);
    if (this.durationIncrement === 1) {
      this.duration = 60;
    } else {
      this.duration = 1;
    }
    this.setDurationMintueOptions();
    this.durationMinutes = this.duration * this.durationIncrement;
    this.isInternalsAvailable && this.internals.setFormValue(this.durationMinutes.toString(), 'duration');
  }

  @Listen('inputOptionChanged')
  inputOptionChangedHandler(event: CustomEvent<{ value: number; name: string }>) {
    debug('nylas-calendar-picker', 'inputOptionChangedHandler', event.detail);
    const { value } = event.detail;
    this.duration = value;
    this.durationMinutes = this.duration * this.durationIncrement;
    this.isInternalsAvailable && this.internals.setFormValue(this.durationMinutes.toString(), this.name);
  }

  @RegisterComponent<NylasEventDuration, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-event-duration',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    const durationOptions = [
      { value: 1, label: 'minute' },
      { value: 60, label: 'hour' },
    ];

    return (
      <Host>
        <div class="nylas-event-duration" part="ned">
          <label htmlFor="duration">
            Event duration<span class="required">*</span>
          </label>
          <div class="nylas-event-duration__wrapper">
            <input-dropdown
              name={'event-duration'}
              options={this.durationMinutesOptions}
              inputValue={this.duration.toString()}
              exportparts="id_dropdown: ned__input_dropdown, id_dropdown-input: ned__input_dropdown-input, id_dropdown-content: ned__input_dropdown-content"
              defaultInputOption={this.durationMinutesOptions.find(i => i.value == this.duration)}
            />
            {this.durationIncrement && (
              <select-dropdown
                name={'event-duration'}
                options={durationOptions}
                pluralizedLabel={this.duration > 1 ? 's' : ''}
                exportparts="sd_dropdown: ned__dropdown, sd_dropdown-button: ned__dropdown-button, sd_dropdown-content: ned__dropdown-content"
                defaultSelectedOption={durationOptions.find(i => i.value == this.durationIncrement)}
                withSearch={false}
              />
            )}
          </div>
        </div>
      </Host>
    );
  }
}
