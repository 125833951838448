import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'paintbrush-fill-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class PaintbrushFillIcon {
  @Prop() width: string = '16';
  @Prop() height: string = '13';

  render() {
    return (
      <svg width="16" height="17" viewBox="-3 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5 2.875V0.375H0.5C0.223858 0.375 0 0.598858 0 0.875V7.37585H10V0.875C10 0.598858 9.77614 0.375 9.5 0.375H8V3.87722C8 4.15337 7.77614 4.37722 7.5 4.37722C7.22386 4.37722 7 4.15337 7 3.87722V0.375H6V2.875C6 3.15114 5.77614 3.375 5.5 3.375C5.22386 3.375 5 3.15114 5 2.875ZM10 8.37585H0V9.37938C0 10.4839 0.895431 11.3794 2 11.3794H3.5V14.8767C3.5 15.7051 4.17157 16.3767 5 16.3767C5.82843 16.3767 6.5 15.7051 6.5 14.8767V11.3794H8C9.10457 11.3794 10 10.4839 10 9.37938V8.37585Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
