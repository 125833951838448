import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug, getBrowser, isNonPrintableKey, sanitize } from '@/utils/utils';
import { AttachInternals, Component, Host, State, h, Element, Prop, Watch, Event, EventEmitter, Listen } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { EVENT_TITLE_TOKENS as eventTitleTokens } from '@/common/constants';
import { Configuration } from '@nylas/core';

interface CustomShadowRoot extends ShadowRoot {
  getSelection: () => Selection | null;
}

type Token = {
  token: string;
  value: string;
  description: string;
};

/**
 * The `nylas-event-title` component is a form input for the title of an event.
 * @part net - The event title container
 * @part net__title - The event title input
 * @part net__dropdown-content - The token options container
 */
@Component({
  tag: 'nylas-event-title',
  styleUrl: 'nylas-event-title.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasEventTitle {
  @Element() host!: HTMLElement;
  @AttachInternals() internals!: ElementInternals;

  // Properties
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * @standalone
   * The title of the event from the cofiguration.
   */
  @Prop() eventTitle?: string = this.selectedConfiguration?.event_booking?.title;
  /**
   * @standalone
   * The name attribute of this component.
   */
  @Prop() name: string = 'title';

  // State variables
  /**
   * Whether to show the tokens dropdown.
   */
  @State() showTokens: boolean = false;
  /**
   * The available token options for the dropdown.
   */
  @State() availableTokens: { label: string; value: string; labelHTML: Token }[] = eventTitleTokens.map(token => ({
    label: token.token,
    value: token.value,
    labelHTML: token,
  }));
  /**
   * The filtered token options for the dropdown based on the current query.
   */
  @State() filteredTokens: { label: string; value: string; labelHTML: Token }[] = this.availableTokens;
  /**
   * The aria-activedescendant attribute value. This is used to indicate the
   * currently active descendant in the tokens dropdown.
   */
  @State() ariaActivedescendant: string = '';
  /**
   * Stores the reference to the current word being typed.
   * This is used to update the event title with the selected token tag when
   * an option is selected from the dropdown by clicking on it.
   */
  @State() currentWord: {
    $value: string;
    fullText: string;
    index: number;
    focusOffset: number;
  } = { $value: '', fullText: '', index: -1, focusOffset: -1 };

  @State() validationError: string = '';
  @State() configEventTitle: string = '';

  // Reference to the title div element
  private titleRef!: HTMLDivElement;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-event-title', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('ariaActivedescendant')
  ariaActivedescendantChangedHandler(newValue: string) {
    debug('nylas-event-title', 'ariaActivedescendantChangedHandler', newValue);
    if (newValue !== '') {
      const activeOption = this.host.shadowRoot?.getElementById(newValue);
      activeOption?.classList.add('active');
    } else {
      const options = this.host.shadowRoot?.querySelectorAll('.token-options li.active');
      options?.forEach(option => option.classList.remove('active'));
    }
  }

  @Watch('selectedConfiguration')
  configChangedHandler(newVal) {
    const title = newVal?.event_booking?.title;
    this.configEventTitle = title;
    if (title) {
      this.updateEventTitleFromProp(title);
    }
  }

  // Events
  /**
   * This event is fired when the value of the event title changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-event-title', 'connectedCallback');
  }

  componentWillLoad() {
    debug('nylas-event-title', 'componentWillLoad');
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-event-title', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.eventTitle = this.selectedConfiguration?.event_booking?.title;
    }
    this.updateEventTitleFromProp(this.eventTitle || '');
  }

  disconnectedCallback() {
    debug('nylas-event-title', 'disconnectedCallback');
  }

  @Listen('formSubmitted', { target: 'window' })
  formSubmittedHandler(event: CustomEvent) {
    debug('nylas-event-title', 'formSubmittedHandler', event);
    if (!this.internals?.validity?.valid) {
      this.validationError = 'Event title is required';
    } else {
      this.validationError = '';
    }
  }

  updateEventTitleFromProp(newValue: string) {
    debug('nylas-event-title', 'eventTitleChangedHandler', newValue);
    const title = newValue || this.configEventTitle;
    if (this.titleRef) {
      this.titleRef.innerHTML = this.highlightTokens(title);
      this.titleRef.focus();
      if (typeof this.internals.setValidity === 'function') {
        if (!title || title === '') {
          this.internals?.setValidity({ customError: true }, `Event title is required`, this.titleRef);
        } else {
          this.internals?.setValidity({ customError: false });
        }
      }
    }
  }

  highlightTokens(title: string) {
    let outputHtml = title;

    eventTitleTokens.forEach(tokenObj => {
      const token = tokenObj.value;
      // Create a regular expression that matches the token as a whole word
      const regex = new RegExp(`(\\${token})(?!\\w)`, 'g');
      // Replace the token with a span element
      outputHtml = outputHtml?.replace(regex, '<span class="highlighted-tag">$1</span>') || '';
    });
    return outputHtml;
  }

  getCurrentSelectionForBrowser() {
    const getSelectionTextData = (nodeValue, offset, node, allSelected) => {
      // Remove zero-width space characters from the text, because they are not visible and cause issues with the selection
      const text = nodeValue.replace(/[\u200B-\u200D\uFEFF]/g, '');
      const dollarIndex = text.lastIndexOf('$');
      const lastWord = text.substring(dollarIndex).split(' ')[0];
      return {
        focusOffset: offset,
        dollarIndex,
        lastWord,
        currentText: text,
        node,
        allSelected,
      };
    };

    // Check if the selection has selected all the text in the node, we need this to handle the case where the user selects all the text and then types or deletes
    const isAllSelected = (selection: Selection) => selection.anchorOffset === 0 && selection.focusOffset === selection.focusNode?.nodeValue?.length;

    const currentBrowser = getBrowser();
    switch (currentBrowser) {
      case 'Chrome':
        const shadowRootSelection = (this.host.shadowRoot as CustomShadowRoot)?.getSelection();
        const focusNode = shadowRootSelection?.focusNode;
        const focusNodeValue = focusNode?.nodeValue || '';
        const allSelected = shadowRootSelection && isAllSelected(shadowRootSelection);
        return getSelectionTextData(focusNodeValue, shadowRootSelection?.focusOffset || -1, focusNode, allSelected);
      case 'Firefox':
        const selection = document.getSelection();
        const anchorNodeValue = selection?.anchorNode?.nodeValue || '';
        const allSelectedFirefox = selection && isAllSelected(selection);
        return getSelectionTextData(anchorNodeValue, selection?.focusOffset || -1, selection?.anchorNode, allSelectedFirefox);
      case 'Safari':
        const windowSelection = window.getSelection();
        const anchorNode = (windowSelection as any)?.getComposedRanges(this.host.shadowRoot as CustomShadowRoot)[0];
        const currentText = anchorNode?.startContainer?.nodeValue || '';
        const allSelectedSafari = windowSelection && isAllSelected(windowSelection);
        return getSelectionTextData(currentText, anchorNode?.endOffset || -1, anchorNode?.startContainer, allSelectedSafari);
      default:
        console.warn('Browser not supported');
        return null;
    }
  }

  handleChange(event: Event) {
    let textContent = (event.target as HTMLDivElement).textContent || '';
    textContent = sanitize(textContent);

    // All browsers handle Selection within Shadow DOM differently, so get the current selection based on the browser
    const currentSelection = this.getCurrentSelectionForBrowser();
    if (!currentSelection) {
      this.updateEventTitle(textContent);
      this.resetDropdown();
      return;
    }
    const { focusOffset, dollarIndex, lastWord, currentText } = currentSelection;

    if (dollarIndex === -1 || focusOffset < dollarIndex) {
      this.updateEventTitle(textContent);
      this.resetDropdown();
      return;
    }
    if (lastWord.startsWith('$')) {
      this.showTokens = true;
      // Update the current word being typed, we need this reference to update the event title with the selected token
      // because the user can select an option from the dropdown by clicking on it, which will not trigger the input event.
      this.currentWord = {
        $value: lastWord,
        fullText: currentText,
        index: dollarIndex,
        focusOffset,
      };
      this.populateSuggestionsDropdown(lastWord);
    } else {
      this.resetDropdown();
    }
    this.updateEventTitle(textContent);
  }

  handleInputKeyDown(event) {
    const selection = this.getCurrentSelectionForBrowser();

    // If no text is remaining in the title, reset the title to an empty string
    if (selection?.allSelected && !isNonPrintableKey(event)) {
      this.titleRef.innerHTML = '';
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      const activeOption = this.host.shadowRoot?.getElementById(this.ariaActivedescendant);
      if (activeOption) {
        activeOption.click();
        this.ariaActivedescendant = '';
      }
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      const activeOption = this.host.shadowRoot?.getElementById(this.ariaActivedescendant);
      if (activeOption) {
        const nextOption = activeOption.nextElementSibling;
        if (nextOption) {
          this.ariaActivedescendant = nextOption.id;
        } else {
          this.ariaActivedescendant = this.filteredTokens[0].label;
        }
      } else {
        this.ariaActivedescendant = this.filteredTokens[0].label;
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      const activeOption = this.host.shadowRoot?.getElementById(this.ariaActivedescendant);
      if (activeOption) {
        const prevOption = activeOption.previousElementSibling;
        if (prevOption) {
          this.ariaActivedescendant = prevOption.id;
        } else {
          this.ariaActivedescendant = this.filteredTokens[this.filteredTokens.length - 1].label;
        }
      } else {
        this.ariaActivedescendant = this.filteredTokens[this.filteredTokens.length - 1].label;
      }
    } else if (event.key === 'Escape') {
      event.preventDefault();
      this.resetDropdown();
    } else if (event.key === 'Backspace' || event.key === 'Delete') {
      const parentNode = selection?.node?.parentNode;
      if (selection?.currentText.startsWith('${') && parentNode) {
        event.preventDefault();
        parentNode.removeChild(selection.node);
        parentNode.remove();
        this.resetDropdown();
      }
      if (this.titleRef.textContent === '' || selection?.allSelected) {
        this.titleRef.innerHTML = '';
        this.updateEventTitle('');
      }
    }
  }

  selectOption(e: Event, option: { label: string; value: string; labelHTML: Token }) {
    e.preventDefault();
    const word = this.currentWord.fullText;
    const dollarWord = this.currentWord.$value;

    // Traverse the DOM to find the text node that contains the current word fullText
    let currentNode = this.titleRef.firstChild;
    let textNode: ChildNode | null = null;

    while (currentNode) {
      if (currentNode.nodeType === 3) {
        const currentNodeText = currentNode.textContent?.replace(/[\u200B-\u200D\uFEFF]/g, '') || '';
        const wordText = word.replace(/[\u200B-\u200D\uFEFF]/g, '');
        if (currentNodeText.includes(wordText)) {
          textNode = currentNode;
          break;
        }
      }
      currentNode = currentNode.nextSibling;
    }

    if (!textNode) {
      return;
    }
    // Split the text node into three parts: text before the token, the token, and text after the token
    const text = textNode.textContent || '';
    const index = text.indexOf(dollarWord);
    const textBefore = text.substring(0, index);
    const textAfter = text.substring(index + dollarWord.length);
    const newTextNode = document.createTextNode(textBefore);
    const newRange = document.createRange();

    // Create a new span element to replace the text node
    const tagSpan = document.createElement('span');
    tagSpan.classList.add('highlighted-tag');
    tagSpan.textContent = `${option.value}`;
    const newTextNodeAfter = document.createTextNode(textAfter);

    if (textAfter !== '') {
      // If there is text after the token, add it to the new span element
      textNode.replaceWith(newTextNode, tagSpan, newTextNodeAfter);
      newRange.setStart(newTextNodeAfter, 1);
    } else {
      // If there is no text after the token, add a zero-width space character (Without this, the cursor will not move outside the highlighted span element)
      const afterNode = document.createTextNode('\u200B');
      textNode.replaceWith(newTextNode, tagSpan, afterNode);
      newRange.setStart(afterNode, 1);
    }

    // Hide the dropdown
    this.resetDropdown();
    this.titleRef.focus();

    // Set the focus to the new span element
    const sel = window.getSelection();
    newRange.collapse(true);
    sel?.removeAllRanges();
    sel?.addRange(newRange);

    // Update the event title with the selected token
    this.updateEventTitle(this.titleRef.textContent || '');
  }

  populateSuggestionsDropdown(query: string = '') {
    this.filteredTokens = this.availableTokens.filter(obj => {
      return obj.label.startsWith(query.toString()) || obj.value.startsWith(query.toString());
    });

    // Set the first option as the active descendant
    if (this.filteredTokens.length > 0) {
      this.ariaActivedescendant = this.filteredTokens[0].label;
    }
  }

  get isInternalsAvailable() {
    return typeof this.internals !== 'undefined' && typeof this.internals.setValidity === 'function' && typeof this.internals.setFormValue === 'function';
  }

  updateEventTitle(text: string) {
    const value = text.replace(/ +/g, ' ');
    if (value === '' || /^[\s]*$/.test(value)) {
      this.validationError = 'Event title is required';
      this.isInternalsAvailable && this.internals?.setValidity({ customError: true }, `Event title is required`, this.titleRef);
    } else {
      this.validationError = '';
      this.isInternalsAvailable && this.internals?.setValidity({ customError: false });
    }
    this.isInternalsAvailable && this.internals?.setFormValue(value, this.name);
    this.valueChanged.emit({ value: value, name: this.name });
  }

  resetDropdown() {
    this.showTokens = false;
    this.ariaActivedescendant = '';
  }

  getLabelHTML(token: { token: string; description: string }) {
    return (
      <div class="token-label">
        <span class="token">{token.token}</span>
        <span class="description">{token.description}</span>
      </div>
    );
  }

  @RegisterComponent<NylasEventTitle, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-event-title',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <div class="nylas-event-title" part="net">
          <label htmlFor="title">
            Event title<span class="required">*</span>
          </label>
          <div
            class={{
              title: true,
              error: this.validationError !== '',
            }}
            part="net__title"
            ref={el => (this.titleRef = el as HTMLDivElement)}
            contentEditable="true"
            onInput={e => this.handleChange(e)}
            onKeyDown={event => this.handleInputKeyDown(event)}
          ></div>
          {this.showTokens && this.filteredTokens?.length > 0 && (
            <div class="token-options" part="net__dropdown-content">
              <ul tabindex="-1" role="listbox" aria-label={this.name} aria-activedescendant={this.ariaActivedescendant}>
                {this.filteredTokens.map(option => (
                  <li
                    tabindex="0"
                    key={option.label}
                    id={option.label}
                    class={{ active: this.ariaActivedescendant === option.label }}
                    onClick={e => this.selectOption(e, option)}
                    role="option"
                  >
                    {this.getLabelHTML(option.labelHTML)}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <span class="help-text">
            Create a dynamic templated event title by typing <code>$</code> and selecting a template item.
            <span class="label-icon">
              <tooltip-component>
                <info-icon slot="tooltip-icon" />
                <span slot="tooltip-content">
                  For example, Interview with <code>{'${invitee}'}</code>
                </span>
              </tooltip-component>
            </span>
          </span>
          {this.validationError != '' && <span class="error-message">{this.validationError}</span>}
        </div>
      </Host>
    );
  }
}
