import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'dragable-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class DragableIcon {
  @Prop() width: string = '20';
  @Prop() height: string = '20';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5 18C16.3284 18 17 18.6716 17 19.5C17 20.3284 16.3284 21 15.5 21C14.6716 21 14 20.3284 14 19.5C14 18.6716 14.6716 18 15.5 18ZM8.5 18C9.32843 18 10 18.6716 10 19.5C10 20.3284 9.32843 21 8.5 21C7.67157 21 7 20.3284 7 19.5C7 18.6716 7.67157 18 8.5 18ZM15.5 11C16.3284 11 17 11.6716 17 12.5C17 13.3284 16.3284 14 15.5 14C14.6716 14 14 13.3284 14 12.5C14 11.6716 14.6716 11 15.5 11ZM8.5 11C9.32843 11 10 11.6716 10 12.5C10 13.3284 9.32843 14 8.5 14C7.67157 14 7 13.3284 7 12.5C7 11.6716 7.67157 11 8.5 11ZM15.5 4C16.3284 4 17 4.67157 17 5.5C17 6.32843 16.3284 7 15.5 7C14.6716 7 14 6.32843 14 5.5C14 4.67157 14.6716 4 15.5 4ZM8.5 4C9.32843 4 10 4.67157 10 5.5C10 6.32843 9.32843 7 8.5 7C7.67157 7 7 6.32843 7 5.5C7 4.67157 7.67157 4 8.5 4Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
