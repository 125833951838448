@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
  width: inherit;
}

.logo-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;

  .input-container {
    flex: 1;

    span.error-message {
      color: var(--nylas-error);
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 100px;
    border: var(--nylas-base-200) solid 1px;
    border-radius: var(--nylas-border-radius-2x);
    overflow: hidden;
    padding: 10px;

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
