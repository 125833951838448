import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'zoom-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class ZoomIcon {
  @Prop() width: string = '20';
  @Prop() height: string = '20';

  render() {
    // Spam icon svg
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.7495 6.85792C19.2077 3.4745 16.5255 0.792345 13.1421 0.250451C12.1175 0.0865155 11.0702 0 10 0C8.92992 0 7.88256 0.0865155 6.85797 0.250451C3.47453 0.792345 0.792373 3.4745 0.250478 6.85792C0.0865433 7.87796 0 8.92987 0 10C0 11.0701 0.0865433 12.1175 0.250478 13.1421C0.792373 16.5255 3.47453 19.2077 6.85797 19.7495C7.88256 19.9135 8.92992 20 10 20C11.0702 20 12.1175 19.9135 13.1421 19.7495C16.5255 19.2077 19.2077 16.5255 19.7495 13.1421C19.9135 12.122 20 11.0701 20 10C20 8.92987 19.9135 7.88251 19.7495 6.85792Z"
          fill="url(#paint0_linear_22_6272)"
        />
        <path
          d="M12.154 12.1676C12.154 12.7596 11.6759 13.2377 11.0839 13.2377H6.42995C5.24598 13.2377 4.28516 12.2769 4.28516 11.0929V7.87794C4.28516 7.28596 4.76328 6.80786 5.35527 6.80786H10.0092C11.1932 6.80786 12.154 7.7687 12.154 8.95267V12.1676ZM14.8589 7.09475L13.2879 8.27412C13.0193 8.47448 12.8598 8.79324 12.8598 9.13022V10.9153C12.8598 11.2523 13.0193 11.5711 13.2879 11.7714L14.8589 12.9508C15.2141 13.2149 15.715 12.9645 15.715 12.5228V7.5228C15.715 7.08109 15.2096 6.83063 14.8589 7.09475Z"
          fill="white"
        />
        <defs>
          <linearGradient id="paint0_linear_22_6272" x1="10" y1="19.9954" x2="10" y2="-0.00455379" gradientUnits="userSpaceOnUse">
            <stop stop-color="#003FAE" />
            <stop offset="0.02" stop-color="#0142B7" />
            <stop offset="0.09" stop-color="#044BD1" />
            <stop offset="0.16" stop-color="#0752E5" />
            <stop offset="0.24" stop-color="#0958F4" />
            <stop offset="0.34" stop-color="#0A5BFC" />
            <stop offset="0.5" stop-color="#0B5CFF" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}
