import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { checkForMissingSlots, debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Host, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';

/**
 * The `nylas-event-info` component is an input form for event information such as title, description, duration, location.
 * This component is a container for the `nylas-event-title`, `nylas-event-description`, `nylas-event-duration`, and `nylas-event-location` components.
 *
 * ```html
 * <nylas-event-info>
 *   <div slot="inputs">
 *     <nylas-event-title></nylas-event-title>
 *     <nylas-event-description></nylas-event-description>
 *     <nylas-event-duration></nylas-event-duration>
 *     <nylas-lcation-component></nylas-event-location>
 *   </div>
 * </nylas-event-info>
 * ```
 *
 * @slot inputs - The event information inputs. This includes the event title, description, duration, and location components.
 */
@Component({
  tag: 'nylas-event-info',
  styleUrl: 'nylas-event-info.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasEventInfo {
  @Element() el!: HTMLElement;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  connectedCallback() {
    debug('nylas-event-info', 'connectedCallback');
  }

  componentWillLoad() {
    debug('nylas-event-info', 'componentWillLoad');
  }

  componentDidLoad() {
    debug('nylas-event-info', 'componentDidLoad');
    checkForMissingSlots(['inputs'], this.el);
  }

  disconnectedCallback() {
    debug('nylas-event-info', 'disconnectedCallback');
  }

  @RegisterComponent<NylasEventInfo, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-event-info',
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <nylas-form-card exportparts="nfc__header: nei__header">
          <h1 slot="header-title" class="nylas-event-info__title" part="nei__title">
            Event information
          </h1>
          <div slot="content" class="nylas-event-info__content">
            <slot name="inputs"></slot>
          </div>
        </nylas-form-card>
      </Host>
    );
  }
}
