import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { checkForMissingSlots, debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Host, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';

/**
 * The `nylas-event-limits` component is an input form for event limits, such as the number of available days in the future and
 * the minimum period of notice that a guest must provide to cancel a booking.
 * This component is a container for the `nylas-limit-future-bookings` and `nylas-min-cancellation-notice` components.
 *
 * ```html
 * <nylas-event-limits>
 *   <div slot="inputs">
 *     <nylas-limit-future-bookings></nylas-limit-future-bookings>
 *     <nylas-min-cancellation-notice></nylas-min-cancellation-notice>
 *   </div>
 * </nylas-event-limits>
 * ```
 *
 * @slot inputs - The event information inputs. This includes the limit future bookings and minimum cancellation notice components.
 */
@Component({
  tag: 'nylas-event-limits',
  styleUrl: 'nylas-event-limits.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasEventLimits {
  @Element() el!: HTMLElement;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  connectedCallback() {
    debug('nylas-event-limits', 'connectedCallback');
  }

  componentWillLoad() {
    debug('nylas-event-limits', 'componentWillLoad');
  }

  componentDidLoad() {
    debug('nylas-event-limits', 'componentDidLoad');
    checkForMissingSlots(['inputs'], this.el);
  }

  disconnectedCallback() {
    debug('nylas-event-limits', 'disconnectedCallback');
  }

  @RegisterComponent<NylasEventLimits, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-event-limits',
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <nylas-form-card exportparts="nfc__header: nel__header">
          <h1 slot="header-title" class="nylas-event-limits__title">
            Event limits
          </h1>
          <div slot="content" class="nylas-event-limits__content">
            <slot name="inputs"></slot>
          </div>
        </nylas-form-card>
      </Host>
    );
  }
}
