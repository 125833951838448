@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
}

.card-wrapper {
  background: var(--nylas-base-0);
  display: flex;
  flex-direction: column;
  font-family: var(--nylas-font-family);
  background-color: var(--nylas-base-0);
  color: var(--nylas-base-900);
  border-radius: var(--nylas-border-radius-3x);
  position: relative;
  padding: 32px;
  width: 562px;

  @media #{$mobile} {
    width: 100%;
    border-radius: 0px;
  }

  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.1),
    0px 3px 6px rgba(0, 0, 0, 0.06);
}

.header {
  overflow-wrap: anywhere;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 400;

  h2 {
    color: var(--nylas-base-900);
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    margin: 0;
  }
}

.description {
  &.title-desc {
    margin-bottom: 2rem;
  }

  &.footer-desc {
    margin-top: 2rem;

    a {
      color: var(--nylas-primary);
      text-decoration: none;
    }
  }

  p {
    margin: 0;
    font-size: 18px;
    color: var(--nylas-base-600);
  }
}

.footer {
  margin-top: 1rem;
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  box-sizing: border-box;
  background-color: var(--nylas-base-25);
  width: 100%;
  border-radius: 0 0 var(--nylas-border-radius-2x) var(--nylas-border-radius-2x);
}

.close-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button-component {
    button {
      padding: 0;
      height: auto;

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

button-component {
  --dot-color: var(--nylas-base-700);
}
