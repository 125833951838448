import { AdditionalParticipant, Calendar, Configuration } from '@nylas/core';
import { ObservableMap, createStore } from '@stencil/store';
import { User } from '@/common/nylas-api-request';

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[] ? RecursivePartial<U>[] : T[P] extends object | undefined ? RecursivePartial<T[P]> : T[P];
};

export interface NylasSchedulerConfigStoreState {
  /**
   * The selected configuration.
   * This is the configuration that is currently selected, use it to populate default values for new configurations.
   */
  selectedConfiguration: RecursivePartial<Configuration>;
  /**
   * The list of configurations.
   * This is the list of configurations that the user has created.
   */
  configurations: Configuration[];
  /**
   * The list of calendars.
   * This is the list of calendars that the user has access to.
   */
  calendars: Calendar[];
  /**
   * The current user.
   * This is the user that is currently logged in.
   */
  currentUser: User | null;
  /**
   * The current action.
   * This is the current action that the user is performing.
   */
  action: 'create' | 'edit' | null;
  /**
   * The additional participants.
   */
  additionalParticipants: AdditionalParticipant[];
  /**
   * The next cursor for list configurations.
   */
  listConfigurationsNextCursor: string | null;
  /**
   * The conference providers.
   */
  conferenceProviders?: Record<'microsoft' | 'zoom' | 'google', string>;
  /**
   * The flag to determine if the slug is required
   */
  requiresSlug: boolean;
}

export type NylasSchedulerConfigStoreType = ReturnType<typeof CreateNylasSchedulerConfigStore>;

export type CreateNylasSchedulerConfigStoreReturnType = ObservableMap<NylasSchedulerConfigStoreState> & {
  /**
   * Reset the selected configuration to the default state.
   * This is used to reset the selected configuration to the default state instead of setting it to an empty object.
   */
  resetSelectedConfiguration: () => void;
};

export function CreateNylasSchedulerConfigStore(defaultState: Partial<NylasSchedulerConfigStoreState> = {}): CreateNylasSchedulerConfigStoreReturnType {
  const defaultSchedulerConfigState: NylasSchedulerConfigStoreState = {
    selectedConfiguration: {},
    configurations: [],
    listConfigurationsNextCursor: null,
    calendars: [],
    currentUser: null,
    action: null,
    additionalParticipants: [],
    requiresSlug: false,
    ...defaultState,
  };
  const store = createStore<NylasSchedulerConfigStoreState>(defaultSchedulerConfigState);

  /**
   * Reset the selected configuration to the default state.
   * This is used to reset the selected configuration to the default state instead of setting it to an empty object.
   */
  function resetSelectedConfiguration() {
    store.set('selectedConfiguration', { ...defaultState['selectedConfiguration'] });
  }

  store.onChange('action', async action => {
    if (!action) {
      resetSelectedConfiguration();
    }
  });

  return {
    ...store,
    resetSelectedConfiguration,
  };
}
