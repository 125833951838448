import { RegisterComponent } from '@/common/register-component';
import { Component, Element, EventEmitter, h, Host, Listen, Prop, State } from '@stencil/core';
import { NylasSchedulerConnector } from '../../..';
import { NylasScheduling } from '../nylas-scheduling/nylas-scheduling';
import { debug } from '@/utils/utils';
import { Event } from '@stencil/core';
import i18next from '@/utils/i18n';
import { ThemeConfig } from '@nylas/core';

/**
 * The `nylas-feedback-form` component is a UI component that displays the booked event card.
 *
 * @part nfbf - The booked event card host.
 * @part nfbf__card - The booked event card.
 * @part nfbf__title - The title of the booked event card.
 * @part nfbf__description - The description of the booked event card.
 * @part nfbf__button-outline - The cancel & reschedule button CTA.
 * @part nfbf__cancel-cta - The cancel button CTA.
 * @part nfbf__reschedule-cta - The reschedule button CTA.
 */
@Component({
  tag: 'nylas-feedback-form',
  styleUrl: 'nylas-feedback-form.scss',
  shadow: true,
})
export class NylasFeedbackForm {
  /**
   * The host element.
   */
  @Element() host!: HTMLNylasFeedbackFormElement;
  /**
   * @standalone
   * The theme configuration.
   */
  @Prop({ attribute: 'theme-config' }) readonly themeConfig?: any;

  /**
   * Feedback modal close event.
   */
  @Event() readonly feedbackModalClosed!: EventEmitter<void>;

  /**
   * Feedback submitted event.
   */
  @Event() readonly feedbackSubmitted!: EventEmitter<{ feedback: string }>;

  /**
   * This is an internal event that is used to trigger validation on the form.
   */
  @Event() readonly triggerValidation!: EventEmitter<void>;

  /**
   * The state to hold the feedback message.
   */
  @State() feedbackMessage: string = '';

  connectedCallback() {}

  disconnectedCallback() {}

  async componentWillLoad() {
    debug(`[nylas-feedback-form] Component will load`);
  }

  async componentDidLoad() {
    debug(`[nylas-feedback-form] Component did load`);
    this.applyThemeConfig(this.themeConfig);
  }

  applyThemeConfig(themeConfig?: ThemeConfig) {
    if (themeConfig) {
      for (const [key, value] of Object.entries(themeConfig)) {
        this.host.style.setProperty(`${key}`, value);
      }
    }
  }

  @Listen('nylasFormInputChanged', { target: 'document' })
  handleFormInputChanged(event: CustomEvent<{ name: string; value: string }>) {
    debug(`[nylas-feedback-form] Form input changed`);
    if (!event.detail.value) {
      return;
    }
    this.triggerValidation.emit();
    if (event.detail.name === 'feedback') {
      this.feedbackMessage = event.detail.value;
    }
  }

  private handleConfirmBookingButtonClicked = () => {
    // TODO: Implement feedback submission
    const validationEvent = this.triggerValidation.emit();
    if (validationEvent.defaultPrevented) {
      return;
    }
    this.feedbackSubmitted.emit({ feedback: this.feedbackMessage });
  };

  private closeModal(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.feedbackModalClosed.emit();
  }

  @RegisterComponent<NylasFeedbackForm, NylasSchedulerConnector, Exclude<NylasScheduling['stores'], undefined>>({
    name: 'nylas-feedback-form',
    stateToProps: new Map([]),
    eventToProps: {
      feedbackSubmitted: async (_event: CustomEvent<{ feedback: string }>, _nylasSchedulerConnector: NylasSchedulerConnector) => {
        // TODO: Implement feedback submission
      },
    },
    localPropsToProp: new Map([['themeConfig', 'themeConfig']]),
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host part="nfbf">
        <div class="card-wrapper" part="nfbfc__card">
          <div class="close-button">
            <button-component tooltip="Close" id="close" variant={'invisible'} onClick={e => this.closeModal(e)} part="nfbf__close-button">
              <close-icon />
            </button-component>
          </div>
          <div class="header">
            <h2 slot="card-title" part="nfbf__title">
              {i18next.t('feedbackFormTitle')}
            </h2>
          </div>
          <div class="description title-desc">
            <p>{i18next.t('feedbackFormDescription')}</p>
          </div>
          <textarea-component
            label={i18next.t('feedbackMessageLabel')}
            required={true}
            maxLength={500}
            part="nfbf__description"
            name="feedback"
            placeholder={i18next.t('feedbackMessagePlaceholder')}
          />
          <div class={'description footer-desc'}>
            <p>
              By sending this report, you agree to Nylas’ access to the data necessary to respond to this issue, subject to Nylas’{' '}
              <a href="https://www.nylas.com/privacy-policy/" target="_blank" rel="nofollow noreferrer noopener">
                Privacy Policy
              </a>
              .
            </p>
          </div>
          <div
            class={{
              footer: true,
            }}
          >
            <button-component variant={'invisible'} onClick={e => this.closeModal(e)} part="nfbf__button-outline nfbf__cancel-cta">
              {`${i18next.t('feedbackModalCloseButton')}`}
            </button-component>
            <button-component variant={'primary'} onClick={this.handleConfirmBookingButtonClicked} part="nfbf__button-outline nfbf__reschedule-cta">
              {`${i18next.t('feedbackModalSubmitButton')}`}
            </button-component>
          </div>
        </div>
      </Host>
    );
  }
}
