import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'envelope-fill-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class EnvelopeFillIcon {
  @Prop() width: string = '16';
  @Prop() height: string = '13';

  render() {
    return (
      <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.0001 3.373V10.5C16.0001 11.8807 14.8808 13 13.5001 13H2.50008C1.11937 13 0 11.8807 0 10.5V3.373L7.74657 7.93097C7.90304 8.02301 8.09711 8.02301 8.25359 7.93097L16.0001 3.373ZM13.5001 0C14.7871 0 15.847 0.972604 15.9849 2.22293L8.00008 6.91991L0.0152595 2.22293C0.153116 0.972604 1.21302 0 2.50008 0H13.5001Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
