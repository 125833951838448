import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'checkmark-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class CheckmarkIcon {
  @Prop() width: string = '18';
  @Prop() height: string = '14';

  render() {
    return (
      <svg width={this.width} height={this.height} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.53033 7.46967C1.23744 7.17678 0.762563 7.17678 0.46967 7.46967C0.176777 7.76256 0.176777 8.23744 0.46967 8.53033L4.96967 13.0303C5.26256 13.3232 5.73744 13.3232 6.03033 13.0303L17.0303 2.03033C17.3232 1.73744 17.3232 1.26256 17.0303 0.96967C16.7374 0.676777 16.2626 0.676777 15.9697 0.96967L5.5 11.4393L1.53033 7.46967Z"
          fill="#293056"
        />
      </svg>
    );
  }
}
