import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug, isValidImage } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../../scheduler-editor/nylas-scheduler-editor/nylas-scheduler-editor';

/**
 * The `nylas-image-input` component is a UI component that allows users input an image URL and see a preview of the image.
 *
 * @part iiu__logo-input-textfield - The image input textfield
 */
@Component({
  tag: 'input-image-url',
  styleUrl: 'input-image-url.scss',
  shadow: true,
  formAssociated: true,
})
export class InputImageUrl {
  @Element() host!: HTMLInputImageUrlElement;
  private readonly componentType: string = 'image-url';

  /**
   * Image
   */
  @Prop({ attribute: 'image-url' }) imageUrl: string = '';
  /**
   * Error message to display when the image URL is invalid.
   */
  @Prop() errorMessage: string = '';
  /**
   * The name of the component
   */
  @Prop() name: string = 'input-image-url';
  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The current image URL state.
   */
  @State() currentImageUrl: string = this.imageUrl;

  /**
   * This event is fired when the image url is changed and the image is invalid.
   */
  @Event() readonly nylasFormInputImageUrlInvalid!: EventEmitter<{ value: string; name: string }>;
  /**
   * This event is fired when the image URL is changed
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
    valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void;
  }>;

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-image-input', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-image-input', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-image-input', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
    this.host.setAttribute('component-type', this.componentType);
  }

  componentDidLoad() {
    debug('nylas-image-input', 'componentDidLoad');
    this.currentImageUrl = this.imageUrl;
    this.setImage(this.imageUrl);
  }

  componentWillUpdate() {
    debug('nylas-image-input', 'componentWillUpdate');
  }

  componentDidUpdate() {
    debug('nylas-image-input', 'componentDidUpdate');
  }

  componentWillRender() {
    debug('nylas-image-input', 'componentWillRender');
  }

  componentDidRender() {
    debug('nylas-image-input', 'componentDidRender');
  }

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-image-input', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Listen('nylasFormInputChanged')
  async nylasFormInputChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    const { name, value } = event.detail;

    if (name === this.name) {
      this.setImage(value);
    }
  }

  @Listen('nylasFormInputBlurred')
  async nylasFormInputBlurHandler(event: CustomEvent<{ value: string; name: string }>) {
    const { name, value } = event.detail;

    if (name === this.name) {
      this.setImage(value);
    }
  }

  setImage(value: string) {
    this.errorMessage = '';

    isValidImage(value)
      .then(() => {
        this.currentImageUrl = value;

        this.internals.setValidity({});
        this.internals.setFormValue(this.currentImageUrl, this.name);
        this.valueChanged.emit({ value: this.currentImageUrl, name: this.name });
      })
      .catch(() => {
        this.errorMessage = 'Invalid image URL.';
        this.nylasFormInputImageUrlInvalid.emit({ value: 'Invalid image URL.', name: this.name });
        if (typeof this.internals.setValidity === 'function') {
          this.internals.setValidity({ customError: true }, 'Invalid image URL.');
        }
      });
  }

  @RegisterComponent<InputImageUrl, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-image-input',
    eventToProps: {
      valueChanged: async (
        event: CustomEvent<{ value: string; name: string; valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void }>,
        _nylasSchedulerConfigConnector: NylasSchedulerConfigConnector,
      ) => {
        const { valueChanged } = event.detail;
        // If a handler is passed, call it.
        if (valueChanged) {
          valueChanged(event);
        }
      },
    },
  })
  render() {
    return (
      <Host>
        <div class="logo-container">
          <div class="input-container">
            <input-component name={this.name} id={this.name} type="text" required={false} exportparts="ic_input: iiu__logo-input-textfield" defaultValue={this.imageUrl ?? ''} />
            {this.errorMessage && <span class="error-message">{this.errorMessage}</span>}
          </div>

          {this.currentImageUrl && !this.errorMessage && (
            <div class="logo">
              <img src={this.currentImageUrl} alt="Logo" />
            </div>
          )}
        </div>
      </Host>
    );
  }
}
