import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'calendar-agenda-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class CalendarAgendaIcon {
  @Prop() width: string = '20';
  @Prop() height: string = '20';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 11.5C14 12.8807 12.8807 14 11.5 14H2.5C1.11929 14 0 12.8807 0 11.5V2.5C0 1.11929 1.11929 0 2.5 0H11.5C12.8807 0 14 1.11929 14 2.5V11.5ZM13 11.5V2.5C13 1.67157 12.3284 1 11.5 1H2.5C1.67157 1 1 1.67157 1 2.5V11.5C1 12.3284 1.67157 13 2.5 13H11.5C12.3284 13 13 12.3284 13 11.5ZM11 3.5C11 3.74546 10.8231 3.94961 10.5899 3.99194L10.5 4H3.5C3.22386 4 3 3.77614 3 3.5C3 3.25454 3.17688 3.05039 3.41012 3.00806L3.5 3H10.5C10.7761 3 11 3.22386 11 3.5ZM11 7C11 7.24546 10.8231 7.44961 10.5899 7.49194L10.5 7.5H3.5C3.22386 7.5 3 7.27614 3 7C3 6.75454 3.17688 6.55039 3.41012 6.50806L3.5 6.5H10.5C10.7761 6.5 11 6.72386 11 7ZM11 10.5C11 10.7455 10.8231 10.9496 10.5899 10.9919L10.5 11H3.5C3.22386 11 3 10.7761 3 10.5C3 10.2545 3.17688 10.0504 3.41012 10.0081L3.5 10H10.5C10.7761 10 11 10.2239 11 10.5Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
