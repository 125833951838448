@import '../../../common/styles/variables.scss';

.switch {
  @include default-css-variables;
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.switch input {
  display: none;
}

.slider {
  background-color: var(--nylas-base-300);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: var(--nylas-base-0);
  bottom: 4px;
  content: '';
  height: 32px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 32px;
}

input:checked + .slider {
  background-color: var(--nylas-success);
}

input:checked + .slider:before {
  transform: translateX(32px);
}

.slider.round {
  border-radius: 35px;
  width: 72px;
  height: 40px;
}

.slider.round:before {
  border-radius: 50%;
}
