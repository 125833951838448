import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'calendar-patterns-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class CalendarPatternsIcon {
  @Prop() width: string = '20';
  @Prop() height: string = '20';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.25 0H11.75C13.2688 0 14.5 1.23122 14.5 2.75V11.25C14.5 12.7688 13.2688 14 11.75 14H3.25C1.73122 14 0.5 12.7688 0.5 11.25V2.75C0.5 1.23122 1.73122 0 3.25 0ZM2 2.75V4H13V2.75C13 2.05964 12.4404 1.5 11.75 1.5H3.25C2.55964 1.5 2 2.05964 2 2.75ZM12.2071 5L8.20711 9H10.7929L13 6.79289V5H12.2071ZM10.7929 5H8.20711L4.20711 9H6.79289L10.7929 5ZM2.79289 9L6.79289 5H4.20711L2 7.20711V9H2.79289ZM2 5.79289L2.79289 5H2V5.79289ZM12.2071 9H13V8.20711L12.2071 9Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
