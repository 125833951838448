import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug, sanitize } from '@/utils/utils';
import { AttachInternals, Component, Host, State, h, Element, Prop, Watch, Event, EventEmitter } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration } from '@nylas/core';

/**
 * The `nylas-event-description` component is a form input for the description of an event.
 * @part nedesc - The event description container
 * @part nedesc__textarea - The event description textarea
 */
@Component({
  tag: 'nylas-event-description',
  styleUrl: 'nylas-event-description.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasEventDescription {
  /**
   * The host element <nylas-event-description>
   */
  @Element() host!: HTMLElement;
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;

  /**
   * @standalone
   * The event description stored in the configuration
   */
  @Prop() eventDescription?: string;

  /**
   * @standalone
   * The name of the event description input.
   */
  @Prop() name: string = 'description';
  /**
   * The selected event description state. This defaults to the event description from the configuration or an empty string.
   */
  @State() description: string = this.eventDescription || this.selectedConfiguration?.event_booking?.description || '';
  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-event-description', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-custom-booking-flow', 'selectedConfigurationChangedHandler', newValue);
    if (newValue?.event_booking?.description) {
      this.description = newValue?.event_booking?.description;
    }
  }

  /**
   * Event emitted when the event description changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  connectedCallback() {
    debug('nylas-event-description', 'connectedCallback');
  }

  componentWillLoad() {
    debug('nylas-event-description', 'componentWillLoad');
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-event-description', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      this.description = this.eventDescription || '';
    }
  }

  disconnectedCallback() {
    debug('nylas-event-description', 'disconnectedCallback');
  }

  handleChange(event) {
    const value = sanitize(event.target.value);
    this.description = value;
    this.internals.setFormValue(value, 'description');
    this.valueChanged.emit({ value: value, name: this.name });
  }

  @RegisterComponent<NylasEventDescription, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-event-description',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <div class="nylas-event-description" part="nedesc">
          <label htmlFor="description">
            Event description
            <span class="label-icon">
              <tooltip-component>
                <info-icon slot="tooltip-icon" />
                <span slot="tooltip-content">The event description appears in the Scheduler Editor’s manager view and in the response payload when a booking is created.</span>
              </tooltip-component>
            </span>
          </label>
          <textarea id="description" part="nedesc__textarea" name="description" value={this.description} maxlength="8192" onInput={e => this.handleChange(e)}></textarea>
        </div>
      </Host>
    );
  }
}
